import React, { useState, useEffect } from 'react';
import './AppSelector.scss';
import styled from 'styled-components/macro';
import { up } from 'styled-breakpoints';
import { Redirect } from 'react-router';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid lightgrey;
  border-radius: 4px;
  padding: 2em;
  margin: 0.2em;
  width: 100%;
  cursor: pointer;
  white-space: nowrap;
  box-sizing: revert;
  background-color: white;

  &:hover {
    background-color: lightgrey;
  }

  ${up('sm')} {
    width: 20%;
  }

  ${up('lg')} {
    width: 15%;
  }

  ${up('xl')} {
    width: 7%;
  }
`;

const StyledH3 = styled.h3`
  font-size: revert;
  margin-top: revert;
  margin-bottom: revert;
  font-weight: revert;
  line-height: revert;
  padding-left: 5px;
`;

function AppSelector(props) {
  const { allowedApps } = props;
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions(
      allowedApps.map((app) => {
        if (app) {
          return (
            <Card
              key={`${app.key}_card`}
              onClick={() => {
                onClick(app.url);
              }}>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <StyledH3>{app.name}</StyledH3>
              </div>
            </Card>
          );
        }
        return <></>;
      })
    );
  }, [allowedApps]);

  const onClick = (url) => {
    window.open(url, '_blank');
  };

  let path = localStorage.getItem('path');

  if (path) {
    try{
      path = JSON.parse(path);
      localStorage.removeItem('path');
      if(path.ts > Date.now() - 7*1000){
        return <Redirect to={path.path} />;
      }
    }catch(err){
      localStorage.removeItem('path');
    }
  }

  return (
    <>
      <StyledH3 style={{ paddingLeft: '10px' }}>Where would you like to go 🚀!?</StyledH3>
      <Container>
        {options || (
          <StyledH3 key="dummy">Nothing to see here! Do you need to request permission??</StyledH3>
        )}
      </Container>
    </>
  );
}

export default AppSelector;
