import React from 'react';
import './AuthLayout.scss';

const AuthLayout = (props) => {
  const { children } = props;
  return (
    <div id="authLayout">           
      {children}
    </div>
  );
};
 
export default AuthLayout;
