/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import BlockIcon from '@material-ui/icons/Block';
import { RestoreOutlined } from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Button } from '@material-ui/core';
import Input from '@material-ui/core/Input';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Tooltip from '@material-ui/core/Tooltip';
import TablePagination from '@material-ui/core/TablePagination';
import SearchIcon from '@material-ui/icons/Search';
import CheckIcon from '@material-ui/icons/Check';
import RefreshIcon from '@material-ui/icons/Refresh';
import styled from 'styled-components/macro';
import FilterListIcon from '@material-ui/icons/FilterList';
import CloseIcon from '@material-ui/icons/Close';
import GroupsIcon from '@material-ui/icons/Group';
import StarIcon from '@material-ui/icons/StarBorderOutlined';

import './UsersList.scss';

const OpenList = styled.div`
  position: absolute;
  background: white;
  padding: 1em;
  line-height: 3;
  border: 1px solid grey;
`;

const ClosedList = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: space-between;
`;

const GroupFilter = styled.div`
  margin-left: 2em;
  width: 15em;
  color: grey;
  border-bottom: 1px solid grey;
  line-height: 2;
  max-width: 30%;
`;

const FilterIcon = styled(FilterListIcon)`
  cursor: pointer;
  color: black;
`;

const ListItem = styled.div`
  cursor: pointer;
  padding-left: 1em;
`;

const ListHeader = styled.div`
  display: flex;
  justify-content: end;
`;

const CloseButton = styled(CloseIcon)`
  cursor: pointer;
`;

const ListBody = styled.div`
  max-height: 500px;
  overflow-y: scroll;
`;

const GroupButton = styled(GroupsIcon)`
  cursor: pointer;
`;

class UsersListComponent extends Component {
  state = {
    page: 1,
    numPages: 0,
    showGroups: false
  };

  componentDidMount() {
    const { page, numPages } = this.props;
    this.setState({ page, numPages });
  }

  render() {
    const {
      editUser,
      disableUserOpenAlert,
      showAppGroupsOpenAlert,
      forceResetPassword,
      createCandidate
    } = this.props;

    const onSearchChange = (searchValue) => {
      this.props.onSearchChange(searchValue);
    };

    const handleChangePage = (event, newPage) => {
      this.props.setPage(event, newPage);
    };

    const onListItemClicked = (e) => {
      let selectedGroups = [...this.props.selectedGroups];

      if (selectedGroups.indexOf(e.target.id) > -1) {
        selectedGroups = selectedGroups.filter((z, x) => {
          return e.target.id !== z;
        });
      } else {
        selectedGroups = [e.target.id];
      }

      this.props.onGroupsChange(selectedGroups);

      setShowGroups(false);
    };

    const resetPassword = (user) => {};

    const setShowGroups = (val) => {
      this.setState({ showGroups: val });
      if (!val) {
        setTimeout(() => {
          this.props.onFiltersChanged();
        }, 300);
      }
    };

    const renderGroupComponent = () => {
      if (this.state.showGroups) {
        return (
          <OpenList>
            <ListHeader>
              <CloseButton
                onClick={() => {
                  setShowGroups(false);
                }}></CloseButton>
            </ListHeader>
            {
              <ListBody>
                {this.props.groups &&
                  this.props.groups.map((v, i) => {
                    let isSelected = this.props.selectedGroups.indexOf(v) > -1;
                    return (
                      <ListItem
                        key={v}
                        id={v}
                        onClick={onListItemClicked}
                        style={{ backgroundColor: isSelected ? 'lightGrey' : 'white' }}>
                        {v}
                      </ListItem>
                    );
                  })}
              </ListBody>
            }
          </OpenList>
        );
      } else if (this.props.selectedGroups.length > 0) {
        return (
          <ClosedList
            onClick={() => {
              setShowGroups(true);
            }}>
            <div>{this.props.selectedGroups.join(',').slice(0, 24) + '...'}</div>
            <FilterIcon></FilterIcon>
          </ClosedList>
        );
      } else {
        return (
          <ClosedList
            onClick={() => {
              setShowGroups(true);
            }}>
            <div>Group filter...</div>
            <FilterIcon></FilterIcon>
          </ClosedList>
        );
      }
    };

    const enableDisable = (usersList, index) => {
      if (usersList.enabled) {
        return (
          <>
            <Tooltip title="Disable">
              <IconButton
                onClick={() =>
                  disableUserOpenAlert(usersList.sub, usersList.username, usersList.enabled, index)
                }
                aria-label="Disable">
                <BlockIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Reset Password">
              <IconButton
                onClick={() => forceResetPassword(usersList.sub, usersList.username, index)}
                aria-label="Force Reset Password">
                <RestoreOutlined />
              </IconButton>
            </Tooltip>
            {
              !usersList.candidateId &&
              <Tooltip title="Create Candidate">
                <IconButton
                  onClick={() => createCandidate(usersList, index)}
                  aria-label="Create Candidate">
                  <StarIcon/>
                </IconButton> 
              </Tooltip>
            }
          </>
        );
      } else {
        return (
          <Tooltip title="Enable">
            <IconButton
              onClick={() =>
                disableUserOpenAlert(usersList.sub, usersList.username, usersList.enabled, index)
              }
              aria-label="Enable">
              <CheckIcon />
            </IconButton>
          </Tooltip>
        );
      }
    };

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className="table-top">
          <Link title={'create new user'} to="/admin/users/add">
            <Button className="generic-flex-display" variant="contained" color="primary">
              <PersonAddIcon className="add-user-icon" />
            </Button>
          </Link>
          <div className="search-bar">
            <Input
              id="searchUsers"
              fullWidth
              placeholder="Search"
              onKeyUp={this.props.onSearchKeyUp}
              startAdornment={<SearchIcon position="start">Kg</SearchIcon>}
              inputProps={{ 'aria-label': 'search-bar' }}
              value={this.props.searchValue}
              onChange={(e) => onSearchChange(e.target.value)}
            />
          </div>
          <GroupFilter>{renderGroupComponent()}</GroupFilter>
          <div title={'clear filters'} className="refresh-container">
            <Button
              className="generic-flex-display"
              variant="contained"
              color="primary"
              onClick={this.props.clearFilters}>
              <RefreshIcon className="refresh-icon" />
            </Button>
          </div>
        </div>
        <TableContainer>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell className="tCell" align="left">
                  <h3>Options</h3>
                </TableCell>
                <TableCell className="tCell" align="left">
                  <h3>Username</h3>
                </TableCell>
                <TableCell className="tCell" align="left">
                  <h3>Enabled</h3>
                </TableCell>
                <TableCell className="tCell" align="left">
                  <h3>Email</h3>
                </TableCell>
                <TableCell className="tCell" align="left">
                  <h3>Name</h3>
                </TableCell>
                <TableCell className="tCell" align="right">
                  <h3>Groups</h3>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.usersList.map((user, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <TableRow key={i}>
                  <TableCell className="tCell" scope="row">
                    <Tooltip title="Edit User">
                      <IconButton onClick={() => editUser(user)} aria-label="Edit">
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    {enableDisable(user, i)}
                  </TableCell>
                  <TableCell className="tCell" align="left">
                    {user.username}
                  </TableCell>
                  <TableCell className="tCell" align="left">
                    {user.enabled.toString()}
                  </TableCell>
                  <TableCell className="tCell" align="left">
                    {user.email}
                  </TableCell>
                  <TableCell className="tCell" align="left">
                    {user.name}
                  </TableCell>
                  <TableCell title={'show user groups'} className="tCell" align="right" scope="row">
                    <Button
                      className="generic-flex-display"
                      variant="contained"
                      color="primary"
                      onClick={() => showAppGroupsOpenAlert(user.username)}>
                      <GroupButton />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[this.props.pageSize]}
          count={
            this.props.usersList.length === this.props.pageSize
              ? this.props.pageSize * (this.props.page + 2)
              : this.props.pageSize * (this.props.page + 1)
          }
          rowsPerPage={this.props.pageSize}
          page={this.props.page}
          onChangePage={handleChangePage}
        />
      </div>
    );
  }
}

export default UsersListComponent;
