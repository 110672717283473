/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { Auth, API } from 'aws-amplify';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Paper from '@material-ui/core/Paper';
import AppRoleTransferList from '../../components/AppRoleTransferList/AppRoleTransferList';
import Loader from '../../components/Loader/Loader';
import apps from '../../utils/appDirectory';
import './EditUsers.scss'


class EditUsersComponent extends Component {

    constructor(props) {
        super(props);

        this.handleInput = this.handleInput.bind(this);

        this.state = {
            reqInProgress: false,
            dataLoaded: false,
            populateGroups: true,
            idToken: null,
            userData: {
            name: '',
            email: '',
            groups: [],
            existingGroups: [],
            },
            selectedApps: [],
            allGroups: [],
            appGroups: [],
            validationErrors: []
        };
    }

    async componentDidMount() {
        const { showToast } = this.props;
        const { dataLoaded } = this.state;
        if (!dataLoaded) {
          try {
            const session = await Auth.currentSession();
            const { idToken } = session;
            const reqData = {
              headers: {
                'Authorization': 'Bearer ' +idToken.jwtToken
              }
            };
            const groupReq = await API.get('AdminAPI', '/resource/admin/groups', reqData);
            const groupsList = await this.getGroupsForOneUser(this.props.user.username);
            await this.setUserGroups(groupsList)
            const { userData } = this.state;

            this.setState({
                userData: {
                    ...userData,
                    existingGroups: groupsList
                },
                idToken: idToken.jwtToken,
                dataLoaded: true,
                allGroups: groupReq
            });
          } catch (e) {
            showToast({
              type: 'error',
              message: 'Error fetching data'
            });
          }
        }
      }

      getGroupsForOneUser = async (userId) => {
        const session = await Auth.currentSession();
        const { idToken } = session;
        const { jwtToken } = idToken;
        const reqData = {
          headers: {
            'Authorization': 'Bearer ' + jwtToken
          }
        };
        const groupList = await API.get('AdminAPI', `/resource/admin/users/${userId}/groups`, reqData);

        return groupList;
      };

      createAvailableRoleList = (selectedApps) => {
        const { allGroups } = this.state;
        let appGroups = [];
        selectedApps.forEach((app) => {
          const groups = allGroups.filter((group) => {
            return group.indexOf(`${app}_`) > -1 && group.indexOf('noEula') === -1;
          });
          appGroups = appGroups.concat(groups);
        });
        this.setState({
          appGroups
        });
      };
    
      updateUser = async () => {
        const { showToast} = this.props;
        const { userData, validationErrors, idToken } = this.state;
        const newValidationErrors = [];
    
        if (this.validateInput('groups', userData.groups)) {
          newValidationErrors.push('groups');
        }
    
        if (newValidationErrors.length === 0) {
          this.setState({
            reqInProgress: true
          });
          try {
            const userBody = {
              remove: userData.existingGroups,
              add: userData.groups
            };
            const userRequest = {
              headers: {
                'Authorization': 'Bearer ' +idToken
              },
              body: userBody
            };
            const response = await API.patch('AdminAPI', '/resource/admin/users/' + this.props.user.sub + '/groups', userRequest);
            this.setState({
              reqInProgress: false
            });
            showToast({
              type: 'success',
              message: response
            });
          } catch (e) {
            showToast({
              type: 'error',
              message: 'Error updating user.'
            });
          }
        } else {
          this.setState({
            validationErrors: [...validationErrors, ...newValidationErrors]
          });
        }
      };
    
      handleInput = (e) => {
        const { name, value } = e.target;
    
        const fieldError = this.validateInput(name, value);
        this.updateValidationState(name, fieldError);
    
        switch (name) {
          case 'apps':
            this.setState({
              selectedApps: value
            });
            this.createAvailableRoleList(value);
            break;
          default:
            return null;
        }
      };
    
      setUserGroups = (groups) => {
        const { userData } = this.state;
        const fieldError = this.validateInput('groups', groups);
        this.updateValidationState('groups', fieldError);
        const newGroups = groups;

        this.setState({
            userData: {
                ...userData,
                groups: newGroups
            }
        });
      };
    
      validateInput = (field, value) => {
        let hasError = false;
        switch (field) {
          case 'apps':
            if (value.length === 0) {
              hasError = true;
            }
            break;
          case 'groups':
            if (value.length === 0) {
              hasError = true;
            }
            break;
          default:
            hasError = false;
        }
    
        if (hasError) {
          return field;
        }
    
        return null;
      };
    
      updateValidationState = (name, fieldError) => {
        const { validationErrors } = this.state;
        const updatedErrors = [...validationErrors];
    
        if (fieldError && !updatedErrors.includes(fieldError)) {
          updatedErrors.push(fieldError);
        } else {
          updatedErrors.splice(validationErrors.indexOf(name), 1);
        }
    
        this.setState({
          validationErrors: updatedErrors
        });
      };

    render() {
        const { user, backToUsersList } = this.props;
        const { dataLoaded, selectedApps, appGroups, validationErrors } = this.state;
        if (!dataLoaded) {
            return <Loader open />;
        }

        return (
            <div className='edit-groups'>
                <Paper className="admin-ui-container edit-user">
                <form>
                    <FormGroup>
                        <FormControl error={validationErrors.includes('apps')}>
                        <InputLabel id="mutiple-checkbox-label">
                            Which Apps Can {user.username} Access?
                        </InputLabel>
                        <Select
                            labelId="mutiple-checkbox-label"
                            id=""
                            multiple
                            onChange={this.handleInput}
                            name="apps"
                            value={selectedApps}
                            renderValue={() => {
                            const selected = selectedApps.map((sel) => {
                                return apps[sel].name;
                            });
                            return selected.join(', ');
                            }}>
                            {Object.entries(apps).map((app) => {
                            const appInfo = app[1];
                            return (
                                <MenuItem key={appInfo.key} value={appInfo.key}>
                                <Checkbox checked={selectedApps.includes(appInfo.key)} />
                                <ListItemText primary={`${appInfo.name}`} />
                                </MenuItem>
                            );
                            })}
                        </Select>
                        {validationErrors.includes('apps') && (
                            <FormHelperText>A user requires access to one or more application</FormHelperText>
                        )}
                        </FormControl>
                    </FormGroup>
                    <FormGroup>
                        <FormControl error={validationErrors.includes('groups')}>
                        <AppRoleTransferList
                            availableRoles={appGroups}
                            handleRoleChanges={this.setUserGroups}
                            activeRoles={this.state.userData.groups}
                        />
                        {validationErrors.includes('groups') && (
                            <FormHelperText style={{ margin: '20px' }}>
                            A user requires at least one role
                            </FormHelperText>
                        )}
                        </FormControl>
                        <div style={{display: 'flex', justifyContent: 'space-around', paddingTop: '20px'}}>
                        <div
                            style={{border: '1px solid lightgrey', padding: '10px', backgroundColor: '#1B3E77', color: 'white', fontWeight: 'bold', cursor: 'pointer'}}
                            onClick={() => backToUsersList()}
                        >
                          Back
                        </div>
                        <div
                            style={{border: '1px solid lightgrey', padding: '10px', backgroundColor: '#1B3E77', color: 'white', fontWeight: 'bold', cursor: 'pointer'}}
                            onClick={this.updateUser}
                        >
                          Confirm
                        </div>
                    </div>
                    </FormGroup>
                </form>
                </Paper>
            </div>
        );
  }
}

export default EditUsersComponent;
