import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import SimpleBar from 'simplebar-react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'simplebar/dist/simplebar.min.css';
import EulaText from './EulaText';

class EulaComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkboxClicked: false
    };
  }

  render() {
    const { accept, requestSent } = this.props;
    const { checkboxClicked } = this.state;
    const disabled = !checkboxClicked || requestSent;

    return (
      <div className="eulaComponent">
        <div className="titleText">Catalyte Software License Agreement</div>
        <SimpleBar className="eulaBox">{ReactHtmlParser(EulaText.formatted)}</SimpleBar>
        <div className="formWrapper">
          <FormControl>
            <FormControlLabel
              value="end"
              control={
                <Checkbox
                  className="idCheck"
                  onChange={() => {
                    this.setState({ checkboxClicked: !checkboxClicked });
                  }}
                />
              }
              label="By checking the box and continuing to the Dashboard, you agree and accept the terms &amp; conditions of the above listed User Agreements."
              labelPlacement="end"
              className="checkBox"
            />
          </FormControl>
          <div className="download-accept-wrapper">
            <a
              className="eula-pdf"
              href={process.env.REACT_APP_env_portal_docs_eula}
              target="_blank"
              rel="noopener noreferrer">
              Download EULA as PDF
            </a>
            <Button
              id="eulaAcceptButton"
              disabled={disabled}
              text="Accept"
              onClick={accept}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default EulaComponent;
