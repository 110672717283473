/* istanbul ignore file */
import Amplify from 'aws-amplify';

function initAmplify() {
  const authConfig = {
    identityPoolId: process.env.REACT_APP_env_indentitypool_id,
    region: process.env.REACT_APP_env_cognito_region,
    userPoolId: process.env.REACT_APP_env_userpool_id,
    userPoolWebClientId: process.env.REACT_APP_env_userpool_client_id,
    oauth: {
      domain: process.env.REACT_APP_env_auth_domain,
      scope: ["email", "openid", "profile", "username", "name"],
      redirectSignIn: process.env.REACT_APP_env_auth_redirect_signin,
      redirectSignOut: process.env.REACT_APP_env_auth_redirect_signout,
      responseType: "code"
    }    
  };

  if (process.env.REACT_APP_env_use_cookie_storage === 'true') {
    authConfig.cookieStorage = {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      domain: process.env.REACT_APP_env_cookie_domain,
      // OPTIONAL - Cookie expiration in days
      expires: 1,
      // OPTIONAL - Cookie secure flag
      // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      secure: process.env.REACT_APP_env_cookie_secure === 'true' ? true : false
    };
  }

  Amplify.configure({
    Auth: authConfig,
    API: {
      endpoints: [
        {
          name: 'AdminAPI',
          endpoint: process.env.REACT_APP_env_user_api_base
        }
      ]
    }
  });

  return Amplify;
}

export default initAmplify;
