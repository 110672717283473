import React, { Component } from 'react';
import { Auth, API } from 'aws-amplify';
import UsersListComponent from '../../components/UsersList/UsersList';
import UsersListAlertComponent from '../../components/UsersList/UsersListAlert';
import UserGroupsAlertComponent from '../../components/UsersList/UserGroups';
import EditUserComponent from '../../components/EditUsers/EditUsers';
import Loader from '../../components/Loader/Loader';
import './UsersList.scss';
import request from '../../utils/api';
import debounce from 'lodash/debounce';

class UsersList extends Component {
  constructor(props) {
    super(props);

    this.disableUser = this.disableUser.bind(this);

    this.state = {
      usersList: null,
      usersListPulled: false,
      disableUserAlertOpen: null,
      showAppGroupsAlertOpen: null,
      username: null,
      userId: null,
      editUser: false,
      userData: null,
      userListFilter: null,
      page: 0,
      tokens: [],
      groups: [],
      selectedGroups: [],
      searchValue: ''
    };
  }

  sessionStorageObj = window.sessionStorage;

  checkUsersExistence = (users) => {
    if (!!users && users.length) {
      return true;
    }
    return false;
  };

  onGroupsChange = (groups) => {
    this.setState({ selectedGroups: groups });
    this.setState({ searchValue: '' });
  };

  onSearchValueChange = async (value) => {
    this.setState({ searchValue: value });
    this.setState({ selectedGroups: [] });

    this.debouncedRefresh();
  };

  async componentDidMount() {
    this.setState({
      usersListPulled: false
    });

    const usersList = await this.getUsersList(0);
    
    this.setState({
      usersList: usersList,
      usersListPulled: true
    })

    await this.getGroups();

    return this.passUsernamesToDashboard(usersList);
  }

  getUsernames = (names) => {
    let returnObject = {};
    for (let name of names) {
      returnObject[name.username] = 1;
    }
    return returnObject;
  };

  setPage = async (event, newPage) => {
    this.refreshUsersList(newPage);
  }

  getUsersList = async (newPage, clearFilters) => {
    let reqToken = newPage === 0 ? null : this.state.tokens[newPage] 
    const selectedGroup = this.state.selectedGroups.length > 0 ? this.state.selectedGroups[0] : null;

    const payload = {
      paginationToken: reqToken,
      filter: clearFilters ? '' : this.state.searchValue,
      group: clearFilters ? null : selectedGroup
    }

    const response = await request('/resource/admin/users', 'PUT', payload);

    if (response?.data) {
      let data = response.data;

      let tokens = [...this.state.tokens];
      tokens[newPage + 1] = data.paginationToken;

      this.setState({
        tokens: tokens,
        pageSize: data.pageSize,
        numUsers: data.numUsers,
        page: newPage
      });

      return data.users;
    } else {
      return [];
    }
  };

  onFiltersChanged = async () => {
    this.refreshUsersList(0);
  }

  onSearchKeyUp = async (e) => {
    if (e.keyCode === 13) {
      this.setState({ loading: true });
    }
  };

  debouncedRefresh = debounce(this.onFiltersChanged, 1000);

  getGroups = async () => {
    const response = await request('/resource/admin/groups', 'GET');
    if (response?.data) {
      this.setState({
        groups: response.data
      });
    }
  };

  // this api gets all groups in the userpool
  getUserGroups = async () => {
    const session = await Auth.currentSession();
    const { idToken } = session;
    const { jwtToken } = idToken;
    const getEndpoint = '/resource/admin/groups';
    const reqData = {
      headers: {
        Authorization: 'Bearer ' + jwtToken
      }
    };
    const userGroups = await API.get('AdminAPI', getEndpoint, reqData);

    return userGroups;
  };

  disableUser = async (userId, username, usersIndex) => {
    const session = await Auth.currentSession();
    const { idToken } = session;
    const { jwtToken } = idToken;
    const { showToast } = this.props;
    const reqData = {
      headers: {
        Authorization: 'Bearer ' + jwtToken
      }
    };

    try {
      await API.del('AdminAPI', `/resource/admin/users/${userId}`, reqData);

      showToast({
        type: 'success',
        message: `Account for ${username} has been disabled.`
      });

      let usersListUpdate = this.state.usersList;
      let item = usersListUpdate[usersIndex];
      item.enabled = false;
      usersListUpdate[usersIndex] = item;

      this.disableUserCloseAlert();
    } catch (e) {
      showToast({
        type: 'error',
        message: 'Error Disabling User'
      });
    }
  };

  enableUser = async (userId, username, usersIndex) => {
    const session = await Auth.currentSession();
    const { idToken } = session;
    const { jwtToken } = idToken;
    const { showToast } = this.props;
    const reqData = {
      headers: {
        Authorization: 'Bearer ' + jwtToken
      }
    };

    try {
      await API.patch('AdminAPI', `/resource/admin/users/${userId}`, reqData);

      showToast({
        type: 'success',
        message: `Account for ${username} has been enabled.`
      });

      let usersListUpdate = this.state.usersList;
      let item = usersListUpdate[usersIndex];
      item.enabled = true;
      usersListUpdate[usersIndex] = item;

      this.disableUserCloseAlert();
    } catch (e) {
      showToast({
        type: 'error',
        message: 'Error Enabling User'
      });
    }
  };


  forceResetPassword = async (userId, username, usersIndex) => {
    const session = await Auth.currentSession();
    const { idToken } = session;
    const { jwtToken } = idToken;
    const { showToast } = this.props;

    try {
      await request(`/resource/admin/users/${userId}/forcereset`, 'PUT');

      showToast({
        type: 'success',
        message: `Force reset password complete for user: ${username}.`
      });

    } catch (e) {
      console.log(e)
      showToast({
        type: 'error',
        message: JSON.stringify(e.errors)
      });
    }
  };

  createCandidate = async (user, index) => {
    const session = await Auth.currentSession();
    const { idToken } = session;
    const { jwtToken } = idToken;
    const { showToast } = this.props;
    try {
      const res = await request(`/resource/staffing/candidate`, 'POST', {emailAddress: user.email});

      showToast({
        type: 'success',
        message: `User candidate created: ${user.username}.`
      });

      let usersListUpdate = this.state.usersList;
      let item = usersListUpdate[index];
      item.candidateId = res.data.candidateId = true;
      usersListUpdate[index] = item;

      return res.candidateId

    } catch (e) {
      console.log(e)
      showToast({
        type: 'error',
        message: JSON.stringify(e)
      });
    }
  };

  disableUserOpenAlert = (userId, username, enabled, index) => {
    this.setState({
      disableUserAlertOpen: true,
      username,
      userId,
      enabled,
      index
    });
  };

  disableUserCloseAlert = () => {
    this.setState({
      disableUserAlertOpen: false,
      username: null,
      userId: null
    });
  };

  passUsernamesToDashboard = (users) => {
    const usernames = this.getUsernames(users);
    this.props.updateUsers(usernames);
  };

  showAppGroupsOpenAlert = (username) => {
    this.setState({
      showAppGroupsAlertOpen: true,
      username
    });
  };

  showAppGroupsCloseAlert = () => {
    this.setState({
      showAppGroupsAlertOpen: false
    });
  };

  editUser = async (userData) => {
    this.setState({
      editUser: true,
      userData: userData
    });
  };

  backToUsersList = async () => {
    this.setState({
      editUser: false
    });
  };

  callShowUserGroupAlertComponent = (username) => {
    return (
      <UserGroupsAlertComponent
        showAppGroupsCloseAlert={this.showAppGroupsCloseAlert}
        username={username}
      />
    );
  };

  refreshUsersList = async (newPage) => {
    this.setState({
      loading: true
    })
    const refreshedList = await this.getUsersList(newPage)
    this.setState({
      usersList: refreshedList,
      loading: false
    });
  }

  clearFilters = async () => {
    this.setState({
      loading: true,
      selectedGroups: [],
      searchValue: ''
    })

    const refreshedList = await this.getUsersList(0, true)

    this.setState({
      usersList: refreshedList,
      loading: false
    });
  };

  callComponentUsersList() {
    const usersList = this.state.usersList;
    return (
      <UsersListComponent
        usersList={usersList}
        pageSize={this.state.pageSize}
        page={this.state.page}
        numUsers={this.state.numUsers}
        setPage={this.setPage}
        onSearchKeyUp={this.onSearchKeyUp}
        editUser={this.editUser}
        onFiltersChanged={this.onFiltersChanged}
        onSearchChange={this.onSearchValueChange}
        onGroupsChange={this.onGroupsChange}
        selectedGroups={this.state.selectedGroups}
        searchValue={this.state.searchValue}
        groups={this.state.groups}
        disableUserOpenAlert={this.disableUserOpenAlert}
        showAppGroupsOpenAlert={this.showAppGroupsOpenAlert}
        clearFilters={this.clearFilters}
        forceResetPassword={this.forceResetPassword}
        createCandidate={this.createCandidate}
      />
    );
  }

  callDisableUserComponent() {
    if (this.state.enabled) {
      return (
        <UsersListAlertComponent
          username={this.state.username}
          userId={this.state.userId}
          enableDisableUser={this.disableUser}
          disableUserCloseAlert={this.disableUserCloseAlert}
          enableDisableText="disable: "
          usersIndex={this.state.index}
        />
      );
    } else {
      return (
        <UsersListAlertComponent
          username={this.state.username}
          userId={this.state.userId}
          enableDisableUser={this.enableUser}
          disableUserCloseAlert={this.disableUserCloseAlert}
          enableDisableText="enable: "
          usersIndex={this.state.index}
        />
      );
    }
  }

  callComponentEditUser = (userData) => {
    return (
      <EditUserComponent
        user={userData}
        backToUsersList={this.backToUsersList}
        showToast={this.props.showToast}
      />
    );
  };

  render() {
    let adminUserList, disableUserAlert, showAppGroupsAlert, editUser;

    if (this.state.usersListPulled && !this.state.editUser) {
      adminUserList = this.callComponentUsersList();
    }

    if (this.state.disableUserAlertOpen) {
      disableUserAlert = this.callDisableUserComponent();
    }

    if (this.state.showAppGroupsAlertOpen) {
      showAppGroupsAlert = this.callShowUserGroupAlertComponent(this.state.username);
    }

    if (this.state.editUser) {
      editUser = this.callComponentEditUser(this.state.userData);
    }

    return (
      <>
        <Loader open={this.state.loading} />
        <div>{disableUserAlert}</div>
        <div className="userList">{adminUserList}</div>
        <div className="UserGroupsAlertComponent">{showAppGroupsAlert}</div>
        <div className="EditUser">{editUser}</div>
      </>
    );
  }
}

export default UsersList;
