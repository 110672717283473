import React, { Component } from 'react';
import { API, Auth } from 'aws-amplify';
import EulaComponent from '../../components/Eula/EulaComponent';
import './Eula.scss';

class Eula extends Component {
  constructor(props) {
    super(props);

    this.state = {
      requestSent: false
    };

    this.handleAccept = this.handleAccept.bind(this);

    this.eulaErrorMessage =
      'Sorry, we are unable to process your EULA acceptance at this time. Please contact productsupport@catayte.io for assistance.';
  }

  handleAccept = async () => {
    this.setState({
      requestSent: true
    });

    const onSuccess = async () => {
      const { refreshTokens, handleUpdatedTokenClaims } = this.props;
      await refreshTokens();
      await handleUpdatedTokenClaims();
    };

    const onError = () => {
      const { showToast } = this.props;
      const toast = {
        type: 'error',
        message: this.eulaErrorMessage
      };
      showToast(toast);
    };

    try {
      let { idToken } = this.props;
      if (!idToken) {
        const session = await Auth.currentSession();
        idToken = session.idToken;
      }
      const { jwtToken, payload } = idToken;
      const { sub } = payload;
      const username = payload['cognito:username'];
      const eulaData = {
        username,
        version: 1.0,
        acceptedEula: true,
        acceptedDate: new Date(Date.now()).toLocaleString()
      };
      const data = {
        headers: {
          'Authorization': 'Bearer ' + jwtToken
        },
        body: eulaData
      };
      const res = await API.put('AdminAPI', `/users/${sub}/eula`, data);
      onSuccess(res);
    } catch (e) {
      onError(e);
    }
  };

  render() {
    const { requestSent } = this.state;
    return (
      <div className="eula-screen">
        <EulaComponent accept={this.handleAccept} requestSent={requestSent} />
      </div>
    );
  }
}

export default Eula;
