import React, { Component } from 'react';
import { Auth, API } from 'aws-amplify';
import { Link } from 'react-router-dom';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import AppRoleTransferList from '../../components/AppRoleTransferList/AppRoleTransferList';
import Loader from '../../components/Loader/Loader';
import apps from '../../utils/appDirectory';
import { validateEmail } from '../../utils/validation';
import { isEmpty } from '../../utils/helpers';
import request from '../../utils/api';

class AddUsers extends Component {
  constructor(props) {
    super(props);

    this.handleInput = this.handleInput.bind(this);

    this.state = {
      reqInProgress: false,
      dataLoaded: false,
      idToken: null,
      userData: {
        firstName: '',
        lastName: '',
        organizations: [],
        name: '',
        email: '',
        groups: [],
      },
      config: {},
      selectedApps: [],
      allGroups: [],
      appGroups: [],
      validationErrors: []
    };
  }

  async componentDidMount() {
    const { showToast } = this.props;
    const { dataLoaded } = this.state;
    if (!dataLoaded) {
      try {
        const session = await Auth.currentSession();
        const { idToken } = session;
        const reqData = {
          headers: {
            'Authorization': 'Bearer ' +idToken.jwtToken
          }
        };
        const groupReq = await API.get('AdminAPI', '/resource/admin/groups', reqData);
        const response = await request('/resource/admin/config', 'GET');
        
        this.setState({
          idToken: idToken.jwtToken,
          dataLoaded: true,
          allGroups: groupReq,
          config: response.data
        });
      } catch (e) {
        showToast({
          type: 'error',
          message: 'Error fetching data'
        });
      }
    }
  }

  addUser = () => {
    const users = this.props.userNames;
    const propsUserObjectIsEmpty = isEmpty(users);
    if(propsUserObjectIsEmpty) { // if the user object is empty create user
        return this.createUser();
    } if (this.checkUsername(this.state.userData.name, users)) {
      return this.createUser()
    }
    return false;
  }

  toggleEulaGroup = (checked) => {
    const { allGroups, userData } = this.state;
    const eulaGroup = allGroups.find((group) => {
      if (group.match(/.*_noEula_upgroup/g)) {
        return group;
      }
      return false;
    });
    const userGroups = userData.groups;
    const hasEulaGroup = userGroups.includes(eulaGroup);
    let updatedGroups;
    if (checked) {
      if (!hasEulaGroup) {
        updatedGroups = userGroups.concat(eulaGroup);
      }
    } else {
      if (hasEulaGroup) {
        userGroups.splice(userGroups.indexOf(eulaGroup), 1);
      }
      updatedGroups = userGroups;
    }

    this.setState({
      userData: {
        ...userData,
        groups: updatedGroups
      }
    });
  };

  createAvailableRoleList = (selectedApps) => {
    const { allGroups } = this.state;
    let appGroups = [];
    selectedApps.forEach((app) => {
      const groups = allGroups.filter((group) => {
        return group.indexOf(`${app}_`) > -1 && group.indexOf('noEula') === -1;
      });
      appGroups = appGroups.concat(groups);
    });
    this.setState({
      appGroups
    });
  };

  /**
   * 
   * @param {string} username of user being added
   * @description verifies the username is not a duplicate
   */
  checkUsername = (name, userList) => {
    // checks the names from edit user state -- passed through props
    const duplicateUsername = Object.prototype.hasOwnProperty.call(userList, name)
    if(duplicateUsername) {
      return this.displayDuplicateUserToast();
    }
    return true;
  }

  createUser = async () => {
    const { showToast } = this.props;
    const { userData, selectedApps, validationErrors, idToken, config } = this.state;
    const newValidationErrors = [];

    if (this.validateInput('firstName', userData.firstName)) {
      newValidationErrors.push('firstName');
    }    

    if (this.validateInput('lastName', userData.lastName)) {
      newValidationErrors.push('lastName');
    } 

    if (this.validateInput('name', userData.name)) {
      newValidationErrors.push('name');
    }

    if (this.validateInput('email', userData.email)) {
      newValidationErrors.push('email');
    }
    if (this.validateInput('organizations', userData.organizations)) {
      newValidationErrors.push('organizations');
    }
    if (this.validateInput('apps', selectedApps)) {
      newValidationErrors.push('apps');
    }

    if (this.validateInput('groups', userData.groups)) {
      newValidationErrors.push('groups');
    }

    if (newValidationErrors.length === 0) {
      this.setState({
        reqInProgress: true
      });
      try {
        const userBody = {
          user_list: [userData]
        };

        userData.emailAddress = userData.email
        await request('/resource/profile/user', 'POST', userData);
        await request('/resource/admin/users', 'POST', userBody);

       
        this.setState({
          reqInProgress: false
        });
        showToast({
          type: 'success',
          message: `['${userData.name}'] created!`
        });
      } catch (e) {
        if(e?.response) {
          let message = e.response.data
          if(message===`['${userData.name}'] could not be added to userpool`) {
            this.displayDuplicateUserToast();
          } else {
            showToast({
              type: 'error',
              message: 'Error creating user.'
            });
          } 
        }
        this.setState({
          reqInProgress: false,
        })
      }
    } else {
      this.setState({
        validationErrors: [...validationErrors, ...newValidationErrors]
      });
    }
  };

  displayDuplicateUserToast = () => {
    this.props.showToast({
      type: 'error',
      message: 'Username already exists'
    });
  }

  handleInput = (e) => {
    const { userData } = this.state;
    const { name, value, checked } = e.target;

    const fieldError = this.validateInput(name, value);
    this.updateValidationState(name, fieldError);

    switch (name) {
      case 'firstName':
        this.setState({
          userData: {
            ...userData,
            firstName: value
          }
        });

        break;  
      case 'lastName':
          this.setState({
            userData: {
              ...userData,
              lastName: value
            }
          });
  
          break; 
      case 'organizations':
        this.setState({
          userData: {
            ...userData,
            organizations: value
          }
        });

        break;                      
      case 'name':
        this.setState({
          userData: {
            ...userData,
            name: value
          }
        });

        break;
      case 'email':
        this.setState({
          userData: {
            ...userData,
            email: value
          }
        });
        break;
      case 'requireEula':
        this.toggleEulaGroup(checked);
        break;
      case 'apps':
        this.setState({
          selectedApps: value
        });
        this.createAvailableRoleList(value);
        break;
      default:
        return null;
    }
  };

  setUserGroups = (groups) => {
    const { allGroups, userData } = this.state;
    const fieldError = this.validateInput('groups', groups);
    this.updateValidationState('groups', fieldError);
    const newGroups = groups;
    const eulaGroup = allGroups.find((group) => {
      if (group.match(/.*_noEula_upgroup/g)) {
        return group;
      }
      return false;
    });
    if (userData.groups.includes(eulaGroup)) {
      newGroups.push(eulaGroup);
    }

    this.setState({
      userData: {
        ...userData,
        groups: newGroups
      }
    });
  };

  validateInput = (field, value) => {
    let hasError = false;
    switch (field) {
      case 'firstName':
        if (value.length === 0) {
          hasError = true;
        }
        break; 
      case 'lastName':
        if (value.length === 0) {
          hasError = true;
        }
        break;   
      case 'organizations':
        if (value.length === 0) {
          hasError = true;
        }
        break;                      
      case 'name':
        if (value.length === 0) {
          hasError = true;
        }
        break;
      case 'email':
        if (value.length === 0 || !validateEmail(value)) {
          hasError = true;
        }
        break;
      case 'apps':
        if (value.length === 0) {
          hasError = true;
        }
        break;
      case 'groups':
        if (value.length === 0) {
          hasError = true;
        }
        break;
      default:
        hasError = false;
    }

    if (hasError) {
      return field;
    }

    return null;
  };

  updateValidationState = (name, fieldError) => {
    const { validationErrors } = this.state;
    const updatedErrors = [...validationErrors];

    if (fieldError && !updatedErrors.includes(fieldError)) {
      updatedErrors.push(fieldError);
    } else {
      updatedErrors.splice(validationErrors.indexOf(name), 1);
    }

    this.setState({
      validationErrors: updatedErrors
    });
  };

  render() {
    const { dataLoaded, selectedApps, appGroups, validationErrors, reqInProgress, config, userData } = this.state;
    if (!dataLoaded) {
      return <Loader open />;
    }
    return (
      <>
        <Paper className="admin-ui-container add-user">
          <h2 style={{ float: 'left' }}>Add New User</h2>
          <Link to="/admin/users" style={{ float: 'right' }}>
            ≪ Back to User List
          </Link>
          <form>
          <TextField
              error={validationErrors.includes('firstName')}
              helperText={validationErrors.includes('firstName') ? 'Please provide a first name' : null}
              id="firstName"
              name="firstName"
              label="First Name"
              fullWidth
              margin="normal"
              onChange={this.handleInput}
            />  
          <TextField
              error={validationErrors.includes('lastName')}
              helperText={validationErrors.includes('lastName') ? 'Please provide a last name' : null}
              id="lastName"
              name="lastName"
              label="Last Name"
              fullWidth
              margin="normal"
              onChange={this.handleInput}
            />                          
            <TextField
              error={validationErrors.includes('name')}
              helperText={validationErrors.includes('name') ? 'Please provide a username' : null}
              id="name"
              name="name"
              label="Username"
              fullWidth
              margin="normal"
              onChange={this.handleInput}
            />
            <TextField
              error={validationErrors.includes('email')}
              helperText={
                validationErrors.includes('email') ? 'Please provide a valid email' : null
              }
              id="email"
              name="email"
              type="email"
              label="Email"
              fullWidth
              margin="normal"
              onChange={this.handleInput}
            />
            <FormGroup style={{ margin: '20px 0' }}>
              <FormControl error={validationErrors.includes('apps')}>
                <InputLabel id="mutiple-checkbox-label">
                  Which Organizations does this user belong to?
                </InputLabel>
                <Select
                  labelId="mutiple-checkbox-label"
                  id=""
                  multiple
                  onChange={this.handleInput}
                  name="organizations"
                  value={userData.organizations}
                  renderValue={() => {
                    return userData.organizations.join(', ');
                  }
                }
                  >
                  {config.organizations.map((app) => {
                    const appInfo = app;
                    return (
                      <MenuItem key={appInfo.name} value={appInfo.name}>
                        <Checkbox checked={userData.organizations.includes(appInfo.name)} />
                        <ListItemText primary={`${appInfo.name}`} />
                      </MenuItem>
                    );
                  })}
                </Select>
                {validationErrors.includes('apps') && (
                  <FormHelperText>A user requires access to one or more application</FormHelperText>
                )}
              </FormControl>
            </FormGroup>            
            <FormGroup style={{ margin: '20px 0' }}>
              <FormControlLabel
                id="requireEulaLabel"
                control={
                  <Switch
                    onChange={this.handleInput}
                    id="requireEula"
                    name="requireEula"
                    color="primary"
                  />
                }
                label="Require User to Accept EULA?"
              />
            </FormGroup>
            <FormGroup style={{ margin: '20px 0' }}>
              <FormControl error={validationErrors.includes('apps')}>
                <InputLabel id="mutiple-checkbox-label">
                  Which Apps Can This User Access?
                </InputLabel>
                <Select
                  labelId="mutiple-checkbox-label"
                  id=""
                  multiple
                  onChange={this.handleInput}
                  name="apps"
                  value={selectedApps}
                  renderValue={() => {
                    const selected = selectedApps.map((sel) => {
                      return apps[sel].name;
                    });
                    return selected.join(', ');
                  }}>
                  {Object.entries(apps).map((app) => {
                    const appInfo = app[1];
                    return (
                      <MenuItem key={appInfo.key} value={appInfo.key}>
                        <Checkbox checked={selectedApps.includes(appInfo.key)} />
                        <ListItemText primary={`${appInfo.name}`} />
                      </MenuItem>
                    );
                  })}
                </Select>
                {validationErrors.includes('apps') && (
                  <FormHelperText>A user requires access to one or more application</FormHelperText>
                )}
              </FormControl>
            </FormGroup>
            <FormGroup>
              <FormControl error={validationErrors.includes('groups')}>
                <AppRoleTransferList
                  availableRoles={appGroups}
                  handleRoleChanges={this.setUserGroups}
                />
                {validationErrors.includes('groups') && (
                  <FormHelperText style={{ margin: '20px' }}>
                    A user requires at least one role
                  </FormHelperText>
                )}
              </FormControl>
            </FormGroup>
            <div
              style={{border: '1px solid lightgrey', padding: '10px', backgroundColor: '#1B3E77', color: 'white', fontWeight: 'bold', cursor: 'pointer', float: 'right'}}
              onClick={this.addUser}
            >Add User</div>
          </form>
        </Paper>
        <Loader open={reqInProgress} />
      </>
    );
  }
}

export default AddUsers;
