/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import Case from 'case';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { not, intersection } from '../../utils/helpers';
import apps from '../../utils/appDirectory';
import './AppRoleTransferList.scss';

class AppRoleTransferList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: [],
      leftList: [],
      rightList: [],
      rolePopulation: true
    };
  }

  componentDidUpdate(prevProps) {
    const { availableRoles } = this.props;

    if (prevProps.availableRoles !== availableRoles) {
      const roles = this.formatRoleList(availableRoles);
      this.setState({
        leftList: roles
      });
    }
  }

  componentDidMount() {
    if(this.props.activeRoles && this.state.rolePopulation) {
      let activeRoles = this.formatRoleList(this.props.activeRoles)
      this.setState({
        rightList: activeRoles,
        rolePopulation: false
      });
    }
  }

  formatRoleList = (groups) => {
    const rolesArray = groups.map((group) => {
      const groupParts = group.split('_');
      const numParts = groupParts.length;
      let groupApp, groupRole, appName;

      if(numParts > 2){
        groupApp = groupParts[2];
        groupRole = groupParts[3];
        appName = apps[groupApp].name;
      }else{
        groupApp = groupParts[0];
        groupRole = groupParts[1]; 
        appName = apps[groupApp].name;
      }

      const roleObject = {
        [group]: `${appName} - ${Case.title(groupRole)}`
      };

      return roleObject;
    });

    return rolesArray;
  };

  handleToggle = (value) => () => {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked
    });
  };

  sendAllRight = () => {
    const { rightList, leftList } = this.state;
    const newRightList = rightList.concat(leftList);
    this.setState({
      leftList: [],
      rightList: newRightList
    });
    this.updateAppliedRoles(newRightList);
  };

  sendCheckedRight = () => {
    const { checked, leftList, rightList } = this.state;
    const leftChecked = intersection(checked, leftList);
    const newRightList = rightList.concat(leftChecked);
    const newLeftList = not(leftList, leftChecked);
    const newCheckedList = not(checked, leftChecked);
    this.setState({
      checked: newCheckedList,
      leftList: newLeftList,
      rightList: newRightList
    });
    this.updateAppliedRoles(newRightList);
  };

  sendCheckedLeft = () => {
    const { checked, leftList, rightList } = this.state;
    const rightChecked = intersection(checked, rightList);
    const newLeftList = leftList.concat(rightChecked);
    const newRightList = not(rightList, rightChecked);
    const newCheckedList = not(checked, rightChecked);
    this.setState({
      checked: newCheckedList,
      leftList: newLeftList,
      rightList: newRightList
    });
    this.updateAppliedRoles(newRightList);
  };

  sendAllLeft = () => {
    const { leftList, rightList } = this.state;
    this.setState({
      leftList: leftList.concat(rightList),
      rightList: []
    });
    this.updateAppliedRoles([]);
  };

  updateAppliedRoles = (roles) => {
    const { handleRoleChanges } = this.props;
    const appliedRoles = roles.map((item) => {
      return Object.keys(item)[0];
    });
    handleRoleChanges(appliedRoles);
  };

  customList = (items) => {
    const { checked } = this.state;
    return (
      <Paper style={{ width: 300, height: 230, overflow: 'scroll' }}>
        <List dense component="div" role="list">
          {items.map((item) => {
            const role = Object.keys(item)[0];
            const friendlyName = Object.values(item);
            const labelId = `transfer-list-item-${role}-label`;

            return (
              <ListItem key={role} role="listitem" button onClick={this.handleToggle(item)}>
                <ListItemIcon>
                  <Checkbox
                    checked={checked.includes(item)}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={`${friendlyName}`} />
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </Paper>
    );
  };

  render() {
    const { leftList, rightList, checked } = this.state;

    const leftChecked = intersection(checked, leftList);
    const rightChecked = intersection(checked, rightList);

    return (
      <Grid container spacing={2} justify="center" alignItems="center">
        <Grid item>
          <h4>Available Roles</h4>
          {this.customList(leftList)}
        </Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              id="send-all-right"
              className="xferlist-button"
              variant="outlined"
              size="small"
              onClick={this.sendAllRight}
              aria-label="move all right"
              disabled={leftList.length === 0}>
              ≫
            </Button>
            <Button
              id="send-selected-right"
              className="xferlist-button"
              variant="outlined"
              size="small"
              onClick={this.sendCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right">
              &gt;
            </Button>
            <Button
              id="send-all-left"
              className="xferlist-button"
              variant="outlined"
              size="small"
              onClick={this.sendCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left">
              &lt;
            </Button>
            <Button
              id="send-selected-left"
              className="xferlist-button"
              variant="outlined"
              size="small"
              onClick={this.sendAllLeft}
              aria-label="move all left"
              disabled={rightList.length === 0}>
              ≪
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          <h4>Applied Roles</h4>
          {this.customList(rightList)}
        </Grid>
      </Grid>
    );
  }
}

export default AppRoleTransferList;
