import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import './AuthComponents.scss';

class NewPasswordInputComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstEnteredPw: null,
      secondEnteredPw: null,
      pwMatch: true
    };
  }

  checkPasswordMatch = (value) => {
    const match = this.state.firstEnteredPw && value === this.state.firstEnteredPw;
    this.setState({
      pwMatch: match,
      secondEnteredPw: value
    });
    this.props.handlePwCheck(match);
  };

  setEnteredPw = (value) => {
    this.setState({
      firstEnteredPw: value
    });
  };

  render() {
    const { firstEnteredPw, pwMatch } = this.state;
    let pwError = firstEnteredPw && firstEnteredPw.length && !pwMatch;
    return (
      <>
        <div >New Password*</div>
        <div >
          <TextField
            style={{marginBottom: '.5em', width: '100%'}}
            autoComplete="new-password"
            type="password"
            name="newpassword"
            className="op-input-field"
            variant="outlined"
            onChange={(e) => {
              this.setEnteredPw(e.target.value);
            }}
          />
        </div>
        <div >Confirm New Password*</div>
        <div >
          <TextField
            style={{marginBottom: '.5em', width: '100%'}}
            autoComplete="new-password"
            type="password"
            name="password"
            className="op-input-field"
            variant="outlined"
            error={pwError}
            onChange={(e) => {
              this.checkPasswordMatch(e.target.value);
              this.props.handleChange(e);
            }}
          />
          {pwError && <p style={{ color: '#f44336' }}>Passwords do not match!</p>}
        </div>
      </>
    );
  }
}

export default NewPasswordInputComponent;
