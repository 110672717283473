import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useForm } from 'react-hook-form';
import { Button } from '@material-ui/core';
import styled from 'styled-components';
import { WithContext as ReactTags } from 'react-tag-input';
import { ErrorMessage } from '@hookform/error-message';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@material-ui/core/Tooltip';

import request from '../../utils/api';
import configs from '../../configuration/configuration.json';

const stateList = configs.states;

const Keys = {
  TAB: 9,
  COMMA: 188,
  ENTER: 13
};

const delimiters = [Keys.TAB, Keys.SPACE, Keys.COMMA, Keys.ENTER];

const Styles = styled.div`
  textarea {
    width: 171px;
  }
  select {
    width: 177px;
  }
  .container {
    // margin: auto;
    display: flex;
    flex-wrap: wrap;
    padding: 5px;    
  }
  .ReactTags__tags {
    position: relative;
  }

  .ReactTags__clearAll {
    cursor: pointer;
    padding: 10px;
    margin: 10px;
    background: #324c82cf;
    color: #fff;
    border: none;
  }

  /* Styles for the input */
  .ReactTags__tagInput {
    border-radius: 2px;
    display: inline-block;
    width: 100%;
  }
  .ReactTags__tags.react-tags-wrapper {
    width: 100%;
    overflow-x: scroll;
    overflow-y: scroll;
  }
  .ReactTags__tagInput input.ReactTags__tagInputField,
  .ReactTags__tagInput input.ReactTags__tagInputField:focus {
    height: 25px;
    margin: 0;
  }

  .ReactTags__editInput {
    border-radius: 1px;
  }

  .ReactTags__tagInputField {
    // width: 170px;
    width: 100%;
  }

  .ReactTags__suggestions {
    max-height: 200px;
    overflow-y: scroll;
    width: 170px !important;
  }
  .ReactTags__selected {
    width: 100%;
    margin-left: 0px;
    max-height: 200px;
    overflow-y: scroll;
  }

  .ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: none !important;

    background: white;
  }

  .ReactTags__selected span.ReactTags__tag {
    margin-left: 0px !important;
  }

  .ReactTags__editTagInput {
    display: inline-flex;
  }

  /* Styles for selected tags */
  .ReactTags__selected span.ReactTags__tag {
    border: 1px solid #ddd;
    background: rgba(82, 110, 130, 0.461);
    color: rgb(29, 27, 27);
    // font-size: 12px;
    display: inline-block;
    padding: 5px;
    margin: 0 5px;
    border-radius: 2px;
  }
  .ReactTags__selected span.ReactTags__tag:first-child {
    margin-left: 0px;
  }
  .ReactTags__selected a.ReactTags__remove {
    color: #aaa;
    margin-left: 5px;
    cursor: pointer;
  }

  /* Styles for suggestions */
  .ReactTags__suggestions {
    // position: absolute;
    top: 60px;
    max-height: 200px;
    overflow-y: scroll;
  }
  .ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
    background: white;
    // width: 200px;
  }
  .ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
    margin: 0;
  }
  .ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
  }
  .ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #b7cfe0;
    cursor: pointer;
  }

  .ReactTags__remove {
    border: none;
    cursor: pointer;
    background: none;
    color: white;
  }

  .flex-grid {
    display: flex;
    flex-wrap: wrap;

    justify-content: space-around;
  }
  .col {
    display: flex;
    flex-wrap: wrap;
    // margin: 5px;
    margin-top: 15px;
    margin-right: 5px;
    padding: 5px;
    flex-direction: column;
    // border: 1px solid lightgrey;
    min-width: 355px;
  }
  .item {
    border-color: blue;
    display: flex;
    justify-content: flex-end;
    margin: 5px;
  }

  .item-label {
    display: flex;
    justify-content: left;
    align-items: left;

    flex: 1;
    align-self: left;
    min-width: 120px;
  }

  .item-input {
    display: flex;
    justify-content: left;
    align-items: left;
    padding: 0 5px;

    flex: 2;
    min-width: 185px;
    max-width: 500px;
  }

  .resume-link {
    margin-top: 15px;
  }

  .upload-resume-btn {
    margin-right: 10px;
  }

  .button {
    padding: 10px;
    marign-left: 15px;
    margin-right: 5px;
    height: 35px;
  }
  input,
  textarea,
  select {
    width: 100%;
  }

  .error {
    color: red;
    font-size: 0.7rem;
    margin: 5px;
    display: flex;
  }
  .error-summary {
    margin-top: 5px;
    padding: 5px;
    border: 1px solid red;
    color: red;
    font-size: 0.7rem;
  }  
`;

function ErrorSummary({ errors }) {
  if (Object.keys(errors).length === 0) {
    return null;
  }
  return (
    <div className="error-summary">
      {Object.keys(errors).map((fieldName) => (
        <ErrorMessage errors={errors} name={fieldName} as="div" key={fieldName} />
      ))}
    </div>
  );
}  

const ErrorMessageContainer = ({ children }) => (
  <span className="error">{children}</span>
);

function Profile(props) {
  const { history, showToast } = props;

  const homeRedirect = () => {
    history.push('/');
  };

  const [values, setValues] = useState({
    available: false,
    firstName: '',
    lastName: '',
    emailAddress: '',
    personalEmail: '',
    title: '',
    desiredPosition: [],
    timeZone: '',
    workerClass: '',
    address: '',
    city: '',
    state: '',
    zip: null,
    phone: null,
    rate: null,
    desiredHours: 40,
    startTime: '',
    endTime: '',
    opentToRelocation: false,
    country: '',
    rating_id: '',
    resumeUrl: '',
    linkedInUrl: '',
    githubUrl: '',
    aboutMe: '',
    workFlavors: [],
    certifications: [],
    topSkills: [],
    otherSkills: [],
    learningSkills: [],
    antiSkills: []
  });

  const [skillSuggestions, setskillSuggestions] = useState([]);

  const [topSkillTags, setTopSkillTags] = useState([]);
  const [otherSkillTags, setOtherSkillTags] = useState([]);
  const [antiSkillTags, setAntiSkillTags] = useState([]);
  const [learningSkillTags, setLearningSkillTags] = useState([]);
  const [certificationTags, setCertificationTags] = useState([]);
  const [downloadIsVisible, setDownloadIsVisible] = useState(false);
  const [fileToDownload, setFileToDownload] = useState('');

  const [config, setConfig] = useState({});
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
    watch,
    control
  } = useForm({ values });

  const onSubmit = async (data) => {
    // snag the skills & certifications and jam them onto the payload

    data.topSkills = topSkillTags.map((tag) => {
      return tag.id;
    });
    data.otherSkills = otherSkillTags.map((tag) => {
      return tag.id;
    });
    data.learningSkills = learningSkillTags.map((tag) => {
      return tag.id;
    });
    data.antiSkills = antiSkillTags.map((tag) => {
      return tag.id;
    });
    data.certifications = certificationTags.map((tag) => {
      return tag.id;
    });

    console.log(data);

    try {
      const response = await request('/resource/profile/user', 'PUT', data);
      console.log('data', response);
      showToast({
        type: 'success',
        message: `Profile saved! ✅`
      });
    } catch (e) {
      if (e?.response?.status != 404) {
        showToast({
          type: 'error',
          message: 'Error Saving User Profile 😳'
        });
      }
    }
  };

  const handleUploadResume = async (event) => {
    const data = new FormData();
    const file = event.target.files[0];
    data.append('file', file);
    if (file) {
      try {
        const response = await request('/resource/profile/user/resume', 'POST', data);
        setFileToDownload(response.data);
        showToast({
          type: 'success',
          message: 'Resume uploaded successfully!'
        });
      } catch (e) {
        if (e?.response?.status != 404) {
          showToast({
            type: 'error',
            message: 'Error Saving Resume'
          });
        }
      }
    }
  };

  const handleDownloadResume = async () => {
    try {
      const response = await request('/resource/profile/user/current/resume', 'GET');
      window.open(response.data);
    } catch (e) {
      if (e?.response?.status != 404) {
        showToast({
          type: 'error',
          message: 'Error Downloading Resume'
        });
      }
    }
  };

  const handleClearResume = (event) => {
    reset({ resumeUrl: '' });
  };

  useEffect(() => {
    async function loadData() {
      const response = await request('/resource/admin/config', 'GET');
      setConfig(response.data);
    }
    loadData();
  }, []);
  useEffect(() => {
    async function loadData() {
      const session = await Auth.currentSession();

      try {
        const response = await request('/resource/profile/user', 'GET');
        if (response?.data) {
          reset({
            ...response.data
          });

          let resume = response.data.resumeUrl?.split('/')[2];
          setFileToDownload(resume);

          setCertificationTags(
            response.data.certifications.map((name) => {
              return { id: name, skill: name };
            })
          );
          setTopSkillTags(
            response.data.topSkills.map((name) => {
              return { id: name, skill: name };
            })
          );
          setOtherSkillTags(
            response.data.otherSkills.map((name) => {
              return { id: name, skill: name };
            })
          );
          setLearningSkillTags(
            response.data.learningSkills.map((name) => {
              return { id: name, skill: name };
            })
          );
          setAntiSkillTags(
            response.data.antiSkills.map((name) => {
              return { id: name, skill: name };
            })
          );
        } else {
          // default to getting email
          console.log('resetting to defaults!', { ...values });
          reset({
            firstName: session.idToken.payload['given_name'],
            lastName: session.idToken.payload['family_name'],
            emailAddress: session.idToken.payload['email'],
            desiredHours: 40
          });
        }
      } catch (e) {
        if (e?.response?.status != 404) {
          showToast({
            type: 'error',
            message: 'Error Fetching User Profile 😳'
          });
        } else {
          reset({
            firstName: session.idToken.payload['given_name'],
            lastName: session.idToken.payload['family_name'],
            emailAddress: session.idToken.payload['email'],
            desiredHours: 40,
            available: true
          });
        }
      }
    }
    loadData();
  }, []);

  useEffect(() => {
    async function getSkills() {
      const response = await request('/resource/skills', 'GET');
      if (response?.data) {
        setskillSuggestions(response.data.skillList);
      }
    }
    getSkills();
  }, []);

  /// top skill tags
  const handleDeleteTopSkillTags = (i) => {
    setTopSkillTags(topSkillTags.filter((tag, index) => index !== i));
  };

  const handleAdditionTopSkillTags = (tag) => {
    if (topSkillTags.length < 10) {
      setTopSkillTags([...topSkillTags, tag]);
    } else {
      showToast({
        type: 'info',
        message: `Top Skill limit exceeded. Pick up to 10!`
      });
    }
  };

  const onTagUpdateTopSkillTags = (i, newTag) => {
    const updatedTags = topSkillTags.slice();
    topSkillTags.splice(i, 1, newTag);
    setTopSkillTags(updatedTags);
  };
  /// end senior tags

  // Other skill tags
  const handleDeleteOtherSkillTags = (i) => {
    setOtherSkillTags(otherSkillTags.filter((tag, index) => index !== i));
  };

  const handleAdditionOtherSkillTags = (tag) => {
    setOtherSkillTags([...otherSkillTags, tag]);
  };

  const onTagUpdateOtherSkillTags = (i, newTag) => {
    const updatedTags = otherSkillTags.slice();
    otherSkillTags.splice(i, 1, newTag);
    setOtherSkillTags(updatedTags);
  };
  // end midlevel tags

  // antiskill tags
  const handleDeleteAntiSkillTags = (i) => {
    setAntiSkillTags(antiSkillTags.filter((tag, index) => index !== i));
  };

  const handleAdditionAntiSkillTags = (tag) => {
    setAntiSkillTags([...antiSkillTags, tag]);
  };

  const onTagUpdateAntiSkillTags = (i, newTag) => {
    const updatedTags = antiSkillTags.slice();
    antiSkillTags.splice(i, 1, newTag);
    setAntiSkillTags(updatedTags);
  };
  // end antiskill tags

  // learning tags
  const handleDeleteLearningSkillTags = (i) => {
    console.log(i);
    setLearningSkillTags(learningSkillTags.filter((tag, index) => index !== i));
  };

  const handleAdditionLearningSkillTags = (tag) => {
    console.log(tag);
    setLearningSkillTags([...learningSkillTags, tag]);
  };

  const onTagUpdateLearningSkillTags = (i, newTag) => {
    console.log('updateing tags', newTag);
    const updatedTags = learningSkillTags.slice();
    learningSkillTags.splice(i, 1, newTag);
    setLearningSkillTags(updatedTags);
  };
  // end learning tags

  // learning tags
  const handleDeleteCertificationTags = (i) => {
    console.log(i);
    setCertificationTags(certificationTags.filter((tag, index) => index !== i));
  };

  const handleAdditionCertificationTags = (tag) => {
    console.log(tag);
    setCertificationTags([...certificationTags, tag]);
  };

  const onTagUpdateCertificationTags = (i, newTag) => {
    console.log('updateing tags', newTag);
    const updatedTags = certificationTags.slice();
    certificationTags.splice(i, 1, newTag);
    setCertificationTags(updatedTags);
  };
  // end learning tags
  return (
    <Styles>
    
      <form
        onSubmit={handleSubmit(onSubmit)}>
        <Button type="submit" variant="contained" color="primary" value="Save" className="button">
          Save
        </Button>
        <Button className="button" variant="contained" onClick={homeRedirect}>
          Cancel
        </Button>

        <div className="flex-grid">
          <div className="col">
            <h3>About me! </h3>
            <div className="item">
              <div className="item-label">Available</div>
              <div className="item-input">
                <input
                  type="checkbox"
                  {...register('available', {
                    setValueAs: (v) => String(v).toLowerCase() == 'true'
                  })}
                />
              </div>
            </div>
            <div className="item">
              <div className="item-label">First Name*</div>
              <div className="item-input">
                <input
                  {...register('firstName', {
                    required: {value: true, message: 'required'},
                    minLength: {
                      value: 2,
                      message: 'First Name must be longer than 2 characters'
                    }
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="firstName"
                  as={<ErrorMessageContainer />}
                />
              </div>
            </div>
            <div className="item">
              <div className="item-label">Last Name*</div>
              <div className="item-input">
                <input
                  {...register('lastName', {
                    required: {value: true, message: 'required'},
                    minLength: {
                      value: 2,
                      message: 'Must be longer than 2 characters'
                    }
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="lastName"
                  as={<ErrorMessageContainer />}
                />
              </div>
            </div>
            <div className="item">
              <div className="item-label">Email*</div>
              <div className="item-input">
                <input readOnly {...register('emailAddress')} />
              </div>
            </div>
            <div className="item">
              <div className="item-label">Phone</div>
              <div className="item-input">
                <input
                  placeholder="000-111-2222"
                  {...register('phone', {
                    pattern: {
                      value: /^\d{3}-\d{3}-\d{4}$/,
                      message: 'Entered value does not match phone format'
                    }
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="phone"
                  as={<ErrorMessageContainer />}
                />
              </div>
            </div>
            <div className="item">
              <div className="item-label">Current Title*</div>
              <div className="item-input">
                <input
                  placeholder="Senior Software Engineer"
                  {...register('title', {
                    required: {value: true, message: 'required'},
                    minLength: {
                      value: 2,
                      message: 'Must be longer than 2 characters'
                    }
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="title"
                  as={<ErrorMessageContainer />}
                />
              </div>
            </div>
            <div className="item">
              <div className="item-label">Experience*</div>
              <div className="item-input">
                <select
                  {...register('ratingId', {
                    required: {value: true, message: 'required'},
                    setValueAs: (v) => parseInt(v)
                  })}
                  aria-invalid={errors.name ? 'true' : 'false'}>
                  <option value="">Select...</option>
                  <option value="1">0-1 years</option>
                  <option value="2">1-2 years</option>
                  <option value="3">3-5 years</option>
                  <option value="4">5-10 years</option>
                  <option value="5">10+ yrs</option>
                </select>{' '}
                <ErrorMessage
                  errors={errors}
                  name="ratingId"
                  as={<ErrorMessageContainer />}
                />
              </div>
            </div>
            <div className="item">
              <div className="item-label">Desired Position(s)*</div>
              <div className="item-input">
                <select
                  multiple
                  {...register('desiredPosition', { 
                    required: {value: true, message: 'required'},
                  })}
                  native
                  aria-invalid={errors.name ? 'true' : 'false'}>
                  {config?.positions?.map((o, i) => {
                    if (o.active) {
                      return (
                        <option value={o.name} key={i}>
                          {o.displayName}
                        </option>
                      );
                    }
                  })}
                </select>{' '}
                <ErrorMessage
                  errors={errors}
                  name="desiredPosition"
                  as={<ErrorMessageContainer />}
                />
              </div>
            </div>

            <div className="item">
              <div className="item-label">Time Zone*</div>
              <div className="item-input">
                <select {...register('timeZone', { 
                    required: {value: true, message: 'required'},
                  })}>
                  <option value="">Select...</option>
                  <option value="EST">EST</option>
                  <option value="CST">CST</option>
                  <option value="MST">MST</option>
                  <option value="PST">PST</option>
                  <option value="AKST">AKST</option>
                  <option value="HST">HST</option>
                </select>{' '}
                <ErrorMessage
                  errors={errors}
                  name="timeZone"
                  as={<ErrorMessageContainer />}
                />
              </div>
            </div>
            <div className="item">
              <div className="item-label">Country*</div>
              <div className="item-input">
                <select
                  {...register('country', { 
                    required: {value: true, message: 'required'},
                  })}
                  aria-invalid={errors.name ? 'true' : 'false'}>
                  <option value="">Select...</option>
                  <option value="USA">USA</option>
                  <option value="Canada">Canada</option>
                </select>{' '}
                <ErrorMessage
                  errors={errors}
                  name="country"
                  as={<ErrorMessageContainer />}
                />
              </div>
            </div>
            <div className="item">
              <div className="item-label">Hrs/Week</div>
              <div className="item-input">
                <input
                  {...register('desiredHours', {
                    pattern: {
                      value: /^\d+$/,
                      message: 'Desired Hours value is not a number'
                    },
                    setValueAs: (v) => parseInt(v)
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="desiredHours"
                  as={<ErrorMessageContainer />}
                />
              </div>
            </div>
            <div className="item">
              <div className="item-label">Rate (USD)*</div>
              <div className="item-input">
                <input
                  placeholder="50"
                  {...register('rate', {
                    required: {value: true, message: 'required'},
                    pattern: {
                      value: /^\d+$/,
                      message: 'Entered value is not a number'
                    }
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="rate"
                  as={<ErrorMessageContainer />}
                />
              </div>
            </div>
            <div className="item" />
            <strong>Working Hours</strong>
            <div className="item">
              <div className="item-label">Start*</div>
              <div className="item-input">
                <input
                  placeholder="07:00"
                  {...register('startTime', {
                    required: {value: true, message: 'required'},
                      pattern: {
                      value: /^([01]\d|2[0-3]):([0-5]\d)$/,
                      message: 'Entered value does not match time format'
                    }
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="startTime"
                  as={<ErrorMessageContainer />}
                />
              </div>
            </div>
            <div className="item">
              <div className="item-label">End*</div>
              <div className="item-input">
                <input
                  placeholder="18:00"
                  {...register('endTime', {
                    required: {value: true, message: 'required'},
                    pattern: {
                      value: /^([01]\d|2[0-3]):([0-5]\d)$/,
                      message: 'Entered value does not match time format'
                    }
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="endTime"
                  as={<ErrorMessageContainer />}
                />
              </div>
            </div>
            <div className="item">
              <div className="item-label">Open to Onsite?</div>
              <div className="item-input">
                <input
                  type="checkbox"
                  {...register('openToRelocation', {
                    setValueAs: (v) => String(v).toLowerCase() == 'true'
                  })}
                />
              </div>
            </div>            
          </div>
          <div className="col">
            <h3>Help us get to know you!</h3>
            <div className="item">
              <div className="item-label">Tell us a story!</div>
              <div className="item-input">
                <textarea
                  placeholder="We know a person is more than a resume!"
                  {...register('aboutMe')}
                  defaultValue={values.aboutMe}
                />
              </div>
            </div>
            <div className="item">
              <div className="item-label">What flavor of work do you do?!</div>
              <div className="item-input">
                <select
                  multiple
                  {...register('workFlavors')}
                  aria-invalid={errors.name ? 'true' : 'false'}>
                  <option value="ui-ux-design">UI/UX Design</option>
                  <option value="frontend-eng">Frontend Engineering</option>
                  <option value="backend-eng">Backend Engineering</option>
                  <option value="data-eng">Data Engineering</option>
                  <option value="data-science">Data Science</option>
                  <option value="infrastructure-devops-sre">Infrastructure/DevOps/SRE</option>
                  <option value="sys-admin-it">System Adminstration/IT</option>
                  <option value="sys-design-arch">System Design/Architecture</option>
                  <option value="project-mgt">Project Management</option>
                </select>{' '}
              </div>
            </div>
            <div className="item">
              <div className="item-label">Top Skills</div>
              <Tooltip
                title="Skills I'm ready to execute at my max rating RIGHT NOW"
                placement="left-start">
                <div className="item-input ReactTags">
                  <ReactTags
                    labelField={'skill'}
                    className={'topSkills'}
                    handleDelete={handleDeleteTopSkillTags}
                    handleAddition={handleAdditionTopSkillTags}
                    delimiters={delimiters}
                    onTagUpdate={onTagUpdateTopSkillTags}
                    maxTags={10}
                    suggestions={skillSuggestions}
                    placeholder="Find or add up to 10 skills..."
                    minQueryLength={1}
                    autofocus={false}
                    allowDeleteFromEmptyInput={true}
                    autocomplete={true}
                    readOnly={false}
                    allowUnique={true}
                    allowDragDrop={true}
                    inline={true}
                    inputFieldPosition={'top'}
                    allowAdditionFromPaste={true}
                    editable={true}
                    clearAll={true}
                    tags={topSkillTags}
                  />
                </div>
              </Tooltip>
            </div>

            <div className="item">
              <div className="item-label">Other Skills</div>
              <Tooltip
                title="Skills I'm ready to execute at my max rating in a couple weeks"
                placement="left-start">
                <div className="item-input ReactTags">
                  <ReactTags
                    labelField={'skill'}
                    className={'otherSkills'}
                    handleDelete={handleDeleteOtherSkillTags}
                    handleAddition={handleAdditionOtherSkillTags}
                    delimiters={delimiters}
                    onTagUpdate={onTagUpdateOtherSkillTags}
                    suggestions={skillSuggestions}
                    placeholder="Find or add a skill..."
                    minQueryLength={1}
                    autofocus={false}
                    allowDeleteFromEmptyInput={true}
                    autocomplete={true}
                    readOnly={false}
                    allowUnique={true}
                    allowDragDrop={true}
                    inline={true}
                    inputFieldPosition={'top'}
                    allowAdditionFromPaste={true}
                    editable={true}
                    clearAll={true}
                    tags={otherSkillTags}
                  />
                </div>
              </Tooltip>
            </div>
            <div className="item">
              <div className="item-label">Skills I'm Learning</div>
              <div className="item-input ReactTags">
                <ReactTags
                  labelField={'skill'}
                  className={'learningSkills'}
                  handleDelete={handleDeleteLearningSkillTags}
                  handleAddition={handleAdditionLearningSkillTags}
                  delimiters={delimiters}
                  onTagUpdate={onTagUpdateLearningSkillTags}
                  suggestions={skillSuggestions}
                  placeholder="Find or add a skill..."
                  minQueryLength={1}
                  autofocus={false}
                  allowDeleteFromEmptyInput={true}
                  autocomplete={true}
                  readOnly={false}
                  allowUnique={true}
                  allowDragDrop={true}
                  inline={true}
                  inputFieldPosition={'top'}
                  allowAdditionFromPaste={true}
                  editable={true}
                  clearAll={true}
                  tags={learningSkillTags}
                />
              </div>
            </div>
            <div className="item">
              <div className="item-label">Never make me use these 🙏!</div>
              <div className="item-input ReactTags">
                <ReactTags
                  style={{ width: '100%' }}
                  labelField={'skill'}
                  className={'antiSkills'}
                  handleDelete={handleDeleteAntiSkillTags}
                  handleAddition={handleAdditionAntiSkillTags}
                  delimiters={delimiters}
                  onTagUpdate={onTagUpdateAntiSkillTags}
                  suggestions={skillSuggestions}
                  placeholder="Find or add a skill..."
                  minQueryLength={1}
                  autofocus={false}
                  allowDeleteFromEmptyInput={true}
                  autocomplete={true}
                  readOnly={false}
                  allowUnique={true}
                  allowDragDrop={true}
                  inline={true}
                  inputFieldPosition={'top'}
                  allowAdditionFromPaste={true}
                  editable={true}
                  clearAll={true}
                  tags={antiSkillTags}
                />
              </div>
            </div>

            <div className="item">
              <div className="item-label">Resume:</div>
              <div className="item-input">
                <Tooltip title="Upload Resume">
                  <Button component="label" variant="contained" color="primary" className="button">
                    <FontAwesomeIcon icon="cloud-upload-alt" size="lg" />
                    <input
                      hidden
                      placeholder="Need to implement"
                      type="file"
                      name="file"
                      accept=".pdf,.docx"
                      onChange={(event) => {
                        handleUploadResume(event);
                      }}
                    />
                  </Button>
                </Tooltip>
                {fileToDownload && (
                  <Tooltip title="Download Resume">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleDownloadResume}
                      className="download-resume-btn">
                      <FontAwesomeIcon icon="cloud-download-alt" size="lg" />
                    </Button>
                  </Tooltip>
                )}
              </div>
            </div>
            <div className="item">
              <div className="item-label">LinkedIn</div>
              <div className="item-input">
                <input {...register('linkedInUrl')} />
              </div>
            </div>
            <div className="item">
              <div className="item-label">GitHub</div>
              <div className="item-input">
                <input {...register('githubUrl')} />
              </div>
            </div>
            <div className="item">
              <div className="item-label">Certifications</div>
              <div className="item-input ReactTags">
                <ReactTags
                  labelField={'skill'}
                  className={'certifications'}
                  handleDelete={handleDeleteCertificationTags}
                  handleAddition={handleAdditionCertificationTags}
                  delimiters={delimiters}
                  onTagUpdate={onTagUpdateCertificationTags}
                  maxTags={100}
                  suggestions={[]}
                  placeholder="Enter a certification!"
                  minQueryLength={1}
                  autofocus={false}
                  allowDeleteFromEmptyInput={true}
                  autocomplete={true}
                  readOnly={false}
                  allowUnique={true}
                  allowDragDrop={true}
                  inline={true}
                  inputFieldPosition={'top'}
                  allowAdditionFromPaste={true}
                  editable={true}
                  clearAll={true}
                  tags={certificationTags}
                />
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginTop: '15px' }}>
          <Button type="submit" variant="contained" color="primary" value="Save" className="button">
            Save
          </Button>
          <Button className="button" variant="contained" onClick={homeRedirect}>
            Cancel
          </Button>
        </div>
      </form>
    </Styles>
  );
}

export default Profile;
