import React from 'react';
import { VerifyContact } from 'aws-amplify-react';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AuthLayout from '../../containers/Auth/AuthLayout';
import './AuthComponents.scss';
import styled from 'styled-components/macro';

const StyledButton = styled.div`
  background-color: #F78D34;
  height: 50px;
  width: 100%;
  color: white;
  font-weight: bold;
  border-radius: 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 0.5em;
  cursor: pointer;
  &:hover{
    background-color: #F3BF64;
  }`

const Link = styled.div`
cursor: pointer;
&:hover{
text-decoration: underline;
}
`;

const FormColumn = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;
`
const TextInput = styled.input`
  width: 96%;
  height: 58px;
  border: 1px solid lightgrey;
  padding-left: 10px;
  border-radius: 6px;
  &:focus{
    border: 1px solid #434343;
    outline: none;
  }
  `


const Form = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 336px;
  width: 100%;
  color: #434343;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

class VerifyContactComponent extends VerifyContact {
  constructor() {
    super();

    this.state.buttonEnabled = false;
    this.state.view = this.state.verifyAttr ? 'submit' : 'verify';
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.verifyAttr !== prevState.verifyAttr) {
      this.setState({
        view: this.state.verifyAttr ? 'submit' : 'verify'
      });
    }
  }

  checkButtonEnable = () => {
    let shouldEnable = false;
    if (this.state.view === 'verify') {
      shouldEnable =
        this.inputs.contact &&
        (this.inputs.checkedValue === 'email' || this.inputs.checkedValue === 'phone_number');
    } else {
      shouldEnable = this.inputs.code && this.inputs.code.length > 0;
    }

    this.setState({
      buttonEnabled: shouldEnable
    });
  };

  handleChangeView = (view) => {
    this.setState(
      {
        view: view
      },
      () => {
        this.checkButtonEnable();
      }
    );
  };

  verifyView = () => {
    const user = this.props.authData;
    if (!user) {
      return null;
    }
    const unverified = user.unverified;
    if (!unverified) {
      return null;
    }
    const email = unverified.email,
      phone_number = unverified.phone_number;
    return (
      <RadioGroup
        name="contact"
        onChange={(e) => {
          this.handleInputChange(e);
          this.checkButtonEnable();
        }}>
        {email && (
          <FormControlLabel
            className="op-logincomponent-label"
            value="email"
            control={<Radio color="default" />}
            label="Email"
            labelPlacement="end"
          />
        )}
        {phone_number && (
          <FormControlLabel
            className="op-logincomponent-label"
            value="phone_number"
            control={<Radio color="default" />}
            label="Phone"
            labelPlacement="end"
          />
        )}
      </RadioGroup>
    );
  };

  submitView = () => {
    return (
      <>
        <div className="op-logincomponent-label">Code*</div>
        <div className="op-logincomponent-input">
          <TextField
            autoComplete="no"
            type="text"
            id="contactVerifyCode"
            name="code"
            className="op-input-field"
            variant="outlined"
            onChange={(e) => {
              this.handleInputChange(e);
              this.checkButtonEnable();
            }}
          />
        </div>
      </>
    );
  };

  showComponent() {
    const { authData } = this.props;
    return (
      <AuthLayout>
        <FormColumn>
          <Form>
            <Row>
              <div id="odysseyLogo" style={{ backgroundImage: `url(${process.env.REACT_APP_env_img_base_path}logo_catalyte.png)` }} />
            </Row>
            <h3>Account recovery requires verified contact information</h3>
            {this.state.view === 'verify' ? this.verifyView() : this.submitView()}
            <div >
              <StyledButton

                id="resetPwButton"
                disabled={!this.state.buttonEnabled}
                onClick={() => {
                  if (this.state.view === 'verify') {
                    this.verify();
                    this.handleChangeView('submit');
                  } else {
                    this.submit();
                  }
                }}
              >{this.state.view === 'verify' ? 'Verify' : 'Submit'}</StyledButton>
            </div>
          </Form>
        </FormColumn>
      </AuthLayout>
    );
  }
}

export default VerifyContactComponent;
