import React from 'react';
import { ConfirmSignUp } from 'aws-amplify-react';
import AuthLayout from '../../containers/Auth/AuthLayout';
import styled from 'styled-components';
import { Auth } from 'aws-amplify';
import './AuthComponents.scss';
import { up } from 'styled-breakpoints';

const LoginImg = styled.div`
  display: none;
  ${up('lg')} {
    display: flex;
    flex-grow: 3;
    background-position: right;
  }
`;

const FormColumn = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;
`;

const TextInput = styled.input`
  width: 100%;
  height: 58px;
  border: 1px solid lightgrey;
  padding-left: 10px;
  border-radius: 6px;
  &:focus {
    border: 1px solid #434343;
    outline: none;
  }
`;
const ConfirmButton = styled.div`
  background-color: #F78D34;
  width: 100%;
  height: 50px;
  color: white;
  font-weight: bold;
  border-radius: 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 0.5em;
  cursor: pointer;
  &:hover {
    background-color: #F3BF64;
  }
`;


class ConfirmSignUpComponent extends ConfirmSignUp {
  constructor(props) {
    super(props);
    this.signUp = this.handleInputChange.bind(this);
    this.confirm = this.confirm.bind(this);
    this.inputs = {
      email: '',
      code: ''
    };
  }

  handleInputChange(e) {
    this.inputs[e.target.id] = e.target.value;
  }

  confirm(e) {
    let username;

    try {
      username = this.inputs.email.split('@')[0];
    } catch (err) {
      console.log('invalid email');
    }

    Auth.confirmSignUp(username, this.inputs.code)
      .then((res) => {
        this.changeState('signIn');
      })
      .catch((err) => {
        console.log(err);
        if (err.code === 'CodeMismatchException') {
          alert('invalid confirmation code');
        }
      });
  }

  showComponent(theme) {
    const Link = styled.div`
      color: #6d9eeb;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    `;

    const ResendWrapper = styled.div`
      display: flex;
      margin-bottom: 1em;
    `;

    const Form = styled.div`
      display: flex;
      flex-direction: column;
      justify-content: center;
    `;

    const Row = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
  `

    return (
      <AuthLayout>
        <FormColumn
          onKeyUp={(e) => {
            if (e.keyCode === 13) {
              this.confirm();
            }
          }}>
          <Form style={{ maxWidth: '336px' }}>
            <Row>
              <div id="odysseyLogo" style={{ backgroundImage: `url(${process.env.REACT_APP_env_img_base_path}logo_catalyte.png)` }} />
            </Row>
            <h4>
              <strong style={{ color: '#434343' }}>Verify your email</strong>
            </h4>
            <div>
              <TextInput
                placeholder={'email'}
                key="email"
                id="email"
                name="email"
                onChange={this.handleInputChange}
                value={this.email}
                style={{ marginBottom: '.5em' }}
              />
              <TextInput
                placeholder="enter your code"
                key="code"
                id="code"
                name="code"
                autoComplete="off"
                onChange={this.handleInputChange}
                value={this.code}
                style={{ marginBottom: '.5em' }}
              />
              <ResendWrapper>
                <div style={{ marginRight: '.3em' }}>Lost your code?</div>
                <Link onClick={this.resend}>Resend Code</Link>
              </ResendWrapper>
            </div>
            <div>
              <ConfirmButton onClick={this.confirm}>Confirm</ConfirmButton>
              <div style={{ marginTop: '1em' }}>
                <Link onClick={() => this.changeState('signIn')}>Back to Sign In</Link>
              </div>
            </div>
          </Form>
        </FormColumn>
      </AuthLayout>
    );
  }
}
export default ConfirmSignUpComponent;
