/* eslint-disable react/destructuring-assignment */
/* eslint-disable prefer-template */
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components/macro';
import { Auth, API } from 'aws-amplify';

const LoadingIndicator = styled.div`
  width: 325px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const GroupName = styled.div`
  padding: 1em;
`

class UserGroupsAlertComponent extends Component {
  constructor(props) {
    super(props);

    this.open = true;
    this.message = '';

    this.state = {
      message: null,
      groups: [],
      loading: true
    };
  }

  async componentDidMount() {
    let groups = await this.getUsersGroups(this.props.username);
    this.setState({groups: groups, loading: false})
  }

  getUsersGroups = async (userId) => {
    const session = await Auth.currentSession();
    const { idToken } = session;
    const { jwtToken } = idToken;
    const reqData = {
      headers: {
        'Authorization': 'Bearer ' + jwtToken
      }
    };
    const groupList = await API.get('AdminAPI', `/resource/admin/users/${userId}/groups`, reqData);

    return groupList;
  };

  render() {
    const { showAppGroupsCloseAlert } = this.props;

    return (
      <div onClick={() => showAppGroupsCloseAlert()}>
        <Dialog
          open={this.open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{this.state.message}</DialogTitle>
          {
            this.state.loading && 
            <LoadingIndicator>
              <div style={{display: 'flex', justifyContent: 'center'}}>
                <CircularProgress />
              </div>
            </LoadingIndicator>
          }
          {
            this.state.groups &&
            this.state.groups.map((v, i) => {
              return(
                <GroupName key={`group_name_${i}`}>{v}</GroupName>
              )
            })
          }
          <DialogActions>
            <Button color="primary" onClick={() => showAppGroupsCloseAlert()}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default UserGroupsAlertComponent;
