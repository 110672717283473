const colors = {
  WHITE: '#ffffff',

  // Multi-Use Brand Colors
  BRAND_NAVY: '#1B3E77',
  BRAND_GRAY: '#919191',
  BRAND_CYAN: '#65BBD3',
  BRAND_LIGHT_GRAY: '#959595',
  BRAND_PINK: '#f4708f',
  BRAND_BLUE: '#22447b',

  // Specific Sections of JN
  APPRENTICE_BACKGROUND: '#fbfbfb7d',
  COACH_BUTTONS_BACKGROUND: '#fbfbfb54',
  COACH_BOXES_BACKGROUND: '#fbfbfb5e',
  COACHING_SESSION_COMMENT: '#f0f3f5e3',
  NEW_SESSION_DELETE_1: '#e3ebf4',
  NEW_SESSION_DELETE_2: '#f4f4f4',
  MODAL_HEADER: '#727272',
  COMMENT_BACKGROUND: '#f4f4f4e3',
  COMMENT_FONT: '#727171',
  SKILL_TOOLTIP: '#fafafa',
  CHECKBOX: '#3cb471',
  ACCESS_LEARNING: '#4492FD',
  COACHING_PLAYBOOK: '#5460C3',
  GRADEBOOK: '#65C7A5',
  BACKGROUND_BRAND_CYAN: '#b7dce7',
  BACKGROUND_BRAND_PINK: '#f4bdca'
};

export default colors;
