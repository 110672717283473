import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const StyledBackdrop = withStyles({
  root: {
    zIndex: 10,
    color: '#fff'
  }
})(Backdrop);

function Loader(props) {
  const { open } = props;
  return (
    <StyledBackdrop open={!!open}>
      <CircularProgress color="inherit" />
    </StyledBackdrop>
  );
}

export default Loader;
