import React, { Component } from "react";
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'

class UsersListAlertComponent extends Component {
    render() {
        const {usersIndex, username, userId, enableDisableUser, disableUserCloseAlert, enableDisableText} = this.props;
        let open = true;

        return (
            <div onClick={() => disableUserCloseAlert()}>
              <Dialog
                open={ open }
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">{"Are you sure you want to " + enableDisableText + username}</DialogTitle>
                <DialogActions>
                  <Button color="primary"
                    onClick={() => disableUserCloseAlert()}>
                    CANCEL
                  </Button>
                  <Button color="primary" autoFocus
                    onClick={() => enableDisableUser(userId, username, usersIndex)}>
                    OK
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
        );
    }
}

export default UsersListAlertComponent;