import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

function ToastComponent(props) {
  const { showToast, toggle, toastType, toastMessage } = props;
  return (
    <Snackbar open={showToast} autoHideDuration={100000} onClose={toggle}>
      <Alert onClose={toggle} severity={toastType}>
        {toastMessage}
      </Alert>
    </Snackbar>
  );
}

export default ToastComponent;
