const fonts = {
  family: {
    ROBOTO: 'Roboto'
  },
  size: {
    SIZE_12: '12px',
    SIZE_14: '14px',
    SIZE_15: '15px',
    SIZE_16: '16px'
  }
};

export default fonts;
