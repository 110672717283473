import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Auth } from 'aws-amplify';

class PrivateRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAllowed: false,
      loaded: false
    };
  }

  async componentDidMount() {
    const session = await Auth.currentSession();
    const userGroups = session.idToken.payload['cognito:groups'];
    let isOPAdmin = false;
    if (userGroups.length > 0) {
      isOPAdmin = userGroups.some((group) => {
        const groupParts = group.split('_');
        if (groupParts.length > 4 && groupParts[2] === 'odysseyPortal' && groupParts[3] === 'admin') {
          return true;
        }
        return false;
      });
    }

    this.setState({
      isAllowed: isOPAdmin,
      loaded: true
    });
  }

  render() {
    const { children, ...rest } = this.props;
    const { isAllowed, loaded } = this.state;
    if (!loaded) return null;
    return (
      <Route
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
        render={({ location }) =>
          isAllowed ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: '/',
                state: { from: location }
              }}
            />
          )
        }
      />
    );
  }
}

export default PrivateRoute;
