import React from 'react';
import { RequireNewPassword } from 'aws-amplify-react';
import Button from '@material-ui/core/Button';
import AuthLayout from '../../containers/Auth/AuthLayout';
import NewPasswordInputComponent from './NewPasswordInputComponent';
import PasswordRules from './PasswordRules';
import './AuthComponents.scss';
import styled from 'styled-components/macro';

const StyledButton = styled.div`
  background-color: #6d9eeb;
  height: 50px;
  width: 100%;
  color: white;
  font-weight: bold;
  border-radius: 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 0.5em;
  cursor: pointer;
  &:hover {
    background-color: #4D85E7;
  }
`;

const Link = styled.div`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const LoginButton = styled.div`
  background-color: #6d9eeb;
  width: 100%;
  height: 50px;
  color: white;
  font-weight: bold;
  border-radius: 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 0.5em;
  cursor: pointer;
  &:hover {
    background-color: #4D85E7;
  }
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 336px;
  width: 100%;
  color: #6d9eeb;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const FormColumn = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;
`;
class RequireNewPasswordComponent extends RequireNewPassword {
  constructor() {
    super();

    this.state = {
      buttonEnabled: false,
      newPwMatch: false,
      fieldsFilled: false
    };

    this.checkButtonEnable = this.checkButtonEnable.bind(this);
    this.handlePwCheck = this.handlePwCheck.bind(this);
  }

  checkButtonEnable = () => {
    const shouldBeEnabled = this.state.fieldsFilled && this.state.newPwMatch;

    this.setState({
      buttonEnabled: shouldBeEnabled
    });
  };

  handlePwCheck = (check) => {
    this.setState(
      {
        newPwMatch: check
      },
      () => {
        this.checkButtonEnable();
      }
    );
  };

  showComponent() {
    return (
      <AuthLayout>
        <FormColumn
          onKeyUp={(e) => {
            if (e.keyCode === 13) {
              this.signUp();
            }
          }}>
          <Form>
            <Row>
              <div id="odysseyLogo" style={{backgroundImage: `url(${process.env.REACT_APP_env_img_base_path}logo_catalyte.png)`}}/>
            </Row>
            <h3>Choose a new password</h3>

            <NewPasswordInputComponent
              handleChange={(e) => {
                this.handleInputChange(e);
                if (e.target.value.length) {
                  this.setState({
                    fieldsFilled: true
                  });
                }
              }}
              handlePwCheck={(check) => this.handlePwCheck(check)}
            />
            <div>
              <StyledButton
                disabled={!this.state.buttonEnabled}
                text="Change"
                onClick={() => this.change()}>
                Change
              </StyledButton>
            </div>
            <Link
              style={{ marginTop: '1em' }}
              onClick={() => {
                this.changeState('signIn');
              }}>
              Back to Login
            </Link>
            <div>
              <PasswordRules />
            </div>
          </Form>
        </FormColumn>
      </AuthLayout>
    );
  }
}

export default RequireNewPasswordComponent;
