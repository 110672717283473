/* eslint-disable no-return-await */
import axios from 'axios';
import { Auth } from 'aws-amplify';

axios.interceptors.response.use(
    (response) => response,
    (err) => {
      if (!err.response) {
        return Promise.reject(err);
      }
      const {
        response: { status },
        // config: { suppressErrorToast }
      } = err;
  
      if (status === 404 && err.config['404Ok'] === true) {
        return Promise.resolve(err.response);
      }
  
      console.error('API Error:', err.response);
      return Promise.reject(err.response.data);
    }
  );

export default async function (endpoint, method, data = null, isBase64Encoded = false, url = process.env.REACT_APP_env_user_api_base) {

    const session = await Auth.currentSession();
    const { idToken } = session;
    const { jwtToken } = idToken;
    let authToken = `Bearer ${jwtToken}`;

    const result = await axios({
        baseURL: url + endpoint,
        method,
        headers: {
            'Content-Type': 'application/json',
            Authorization: authToken,
            'Accept': '*/*'
        },
        data,
        isBase64Encoded,
        mode: 'no-cors'
    })
        .catch(error => {
            throw error
        });

    return result;
}
