import React from 'react';
import styled from 'styled-components/macro';
import colors from '../../colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Dropdown from 'react-bootstrap/Dropdown';

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  margin-right: 10px;
`;

const StyledDropdownToggle = styled(Dropdown.Toggle)`
  background-color: unset;
  color: ${colors.WHITE};
  border: none;

  &:active {
    background-color: ${colors.WHITE} !important;
    outline: none !important;
    color: black !important;
    border: none !important;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }

  &:focus {
    color: black !important;
    outline: none !important;
    background-color: ${colors.WHITE} !important;
    border: none !important;
    box-shadow: none !important;
  }
`;

const StyledDropdownMenu = styled(Dropdown.Menu)`
  margin-top: 5px;
`;

export const IconDropdown = ({ icon, children }) => {
  return (
    <>
      <Dropdown align="end">
        <StyledDropdownToggle>
          <StyledFontAwesomeIcon icon={icon} size="lg" />
        </StyledDropdownToggle>
        <StyledDropdownMenu>{children}</StyledDropdownMenu>
      </Dropdown>
    </>
  );
};

export default IconDropdown;
