import {constants as c} from './constants';

const apps = {
  portal: {
    key: 'portal',
    name: c.PORTAL_APP_NAME,
  }, 
  catio: {
      key: 'catio',
      name: c.CATIO_APP_NAME,
      url: c.CATIO_APP_URL,
      description: c.CATIO_APP_DESCRIPTION
    },    
  odysseyPortal: {
    key: 'odysseyPortal',
    name: c.ODYSSEY_APP_NAME,
    url: c.ODYSSEY_APP_URL,
    description: c.ODYSSEY_APP_DESCRIPTION
  },
  profile: {
    key: 'profile',
    name: c.PROFILE_APP_NAME,
    url: c.PROFILE_APP_URL,
    description: c.PROFILE_APP_DESCRIPTION
  },
  screen: {
    key: 'screen',
    name: c.SCREEN_APP_NAME,
    url: c.SCREEN_APP_URL,
    description: c.SCREEN_APP_DESCRIPTION
  },
  journey: {
    key: 'journey',
    name: c.JOURNEY_APP_NAME,
    url: c.JOURNEY_APP_URL,
    description: c.JOURNEY_APP_DESCRIPTION
  },
  invoice: {
    key: 'invoice',
    name: c.INVOICE_APP_NAME,
    url: c.INVOICE_APP_URL,
    description: c.INVOICE_APP_DESCRIPTION
  },
  tableau: {
    key: 'tableau',
    name: c.TABLEAU_APP_NAME,
    url: c.TABLEAU_APP_URL,
    description: c.TABLEAU_APP_DESCRIPTION
  },
  staffmatch: {
    key: 'staffmatch',
    name: c.STAFF_MATCH_APP_NAME,
    url: c.STAFF_MATCH_APP_URL,
    description: c.STAFF_MATCH_APP_DESCRIPTION
  },
  feedback: {
    key: 'feedback',
    name: c.FEEDBACK_APP_NAME,
    url: c.FEEDBACK_APP_URL,
    description: c.FEEDBACK_APP_DESCRIPTION
  },
};

export default apps;
