import React from 'react';

function PasswordRules() {
  return (
    <>
      <p>*8 character minimum</p>
      <p>*At least 1 capital letter</p>
      <p>*At least 1 lowercase letter</p>
      <p>*At least 1 number</p>
      <p>*At least 1 special character</p>
    </>
  );
}

export default PasswordRules;
