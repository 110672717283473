export function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

export function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

export const isEmpty = (obj) => {
  for (const key in obj) {
    if (obj[key]) return false;
  }
  return true;
};

export const getParameterByName = (name, url = window.location.href) => {
  /* eslint no-useless-escape: "off" */
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
